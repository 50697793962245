/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/jsx-no-bind */
import React, { useEffect, useState } from 'react';
import { Plus, RemindFill } from '@rsuite/icons';

import {
  Table,
  InputNumber,
  Button,
  FlexboxGrid,
  Modal,
  Form,
  IconButton,
  Schema,
  Toggle,
} from 'rsuite';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';

import { Container } from '~/styles/tabela';

import api from '~/services/api';
import {
  CelulaEditavel,
  CelulaAcao,
} from '~/components/Celulas/components/CelulaEditavel';
import { TabelaSemPaginacao } from '~/components/TabelaSemPaginacao';
import AdicionarIconeDialog from './components/AdicionarIconeDialog';
import SearchTable from '../components/SearchTable';
import { ButtonFullBlue } from '../../../components/Buttons/ButtonFullBlue/styles';

const { Cell } = Table;

const { HeaderCell, Column } = Table;

const { StringType, NumberType } = Schema.Types;

const modelo = Schema.Model({
  profissao: StringType().isRequired('Serviço Obrigatório'),
  habilidade: StringType().isRequired('Habilidade Obrigatória'),
  valor_cobrar: NumberType().isRequired('Valor a Cobrar Obrigatório'),
  valor_repassar: NumberType().isRequired('Valor a Receber Obrigatório'),
});

function Profissoes() {
  const [dados, setDados] = useState({
    listaInicial: [],
    profissoesLista: [],
    profissoesListaFiltrada: [],
    filtro: '',
  });
  const [apagarProfissaoDialog, setApagarProfissaoDialog] = useState({
    exibir: false,
    profissao: {},
  });
  const [adicionarIconeDialog, setAdicionarIconeDialog] = useState({
    exibir: false,
    profissao: {},
  });
  const [carregando, setCarregando] = useState(true);
  const [adicionarProfissaoDialog, setAdicionarProfissaoDialog] =
    useState(false);
  const [camposInput, setCamposInput] = useState({});

  async function buscarProfissoes() {
    const resposta = await api.get('profissao');
    const resposta1 = await api.get('profissao');
    const lista = resposta.data;
    setDados({
      listaInicial: resposta1.data,
      profissoesLista: lista,
      profissoesListaFiltrada: lista,
    });
    setCarregando(false);
  }

  async function bloquearProfissao(profissao, status) {
    try {
      setCarregando(true);
      profissao.profissao_bloqueada = !status;
      await api.put(`profissao/${profissao.id}`, profissao);
      setCarregando(false);
      buscarProfissoes();
      toast.success('Status atualizado com sucesso');
    } catch (error) {
      toast.error(error.message);
    }
  }

  const CelulaAcaoBloquear = ({ rowData, ...props }) => (
    <Cell {...props}>
      <Toggle
        defaultChecked={rowData.profissao_bloqueada}
        onChange={() => bloquearProfissao(rowData, rowData.profissao_bloqueada)}
      />
    </Cell>
  );

  useEffect(() => {
    buscarProfissoes();
  }, []);

  function filtrarLista(texto) {
    setDados({
      ...dados,
      profissoesListaFiltrada: dados.profissoesLista.filter((profissao) =>
        profissao.profissao
          .toString()
          .toUpperCase()
          .includes(texto ? texto.toUpperCase() : '')
      ),
      filtro: texto,
    });
  }

  function abrirApagarDialog(profissao) {
    setApagarProfissaoDialog({
      exibir: true,
      profissao,
    });
  }

  function fecharApagarDialog() {
    setApagarProfissaoDialog({
      exibir: false,
      profissao: {},
    });
  }

  async function excluirProfissao() {
    try {
      await api.delete(`/profissao/${apagarProfissaoDialog.profissao.id}`);
      toast.success('Serviço excluido com sucesso');
      fecharApagarDialog();
    } catch (e) {
      toast.error(e.message);
    }

    buscarProfissoes();
  }

  async function adicionarProfissao() {
    try {
      setCarregando(true);
      const resposta = await api.post(`/profissao`, camposInput);
      await api.post('/habilidade', {
        habilidade: camposInput.habilidade,
        id_profissao: resposta.data.id,
      });
      toast.success('Serviço criado com sucesso');
      setCarregando(false);
      setAdicionarProfissaoDialog(false);
    } catch (e) {
      toast.error(e.message);
    }

    buscarProfissoes();
  }

  function alterarTextoCelula(id, key, value) {
    const novaLista = Object.assign([], dados.profissoesLista);
    novaLista.find((item) => item.id === id)[key] = value;
    setDados({ ...dados, profissoesLista: novaLista });
    filtrarLista(dados.filtro);
  }

  async function alterarEstadoCelula(id) {
    const novaLista = Object.assign([], dados.profissoesLista);
    const item = novaLista.find((profissao) => profissao.id === id);
    const itemInicial = dados.listaInicial.find(
      (profissao) => profissao.id === id
    );
    if (
      item.status === 'EDIT' &&
      (item.profissao !== itemInicial.profissao ||
        item.valor_cobrar !== itemInicial.valor_cobrar ||
        item.valor_repassar !== itemInicial.valor_repassar)
    ) {
      try {
        setCarregando(true);
        await api.put(`profissao/${id}`, item);
        toast.success('Serviço atualizado com sucesso');
        buscarProfissoes();
      } catch (error) {
        toast.error(error.message);
      }
      setCarregando(false);
    } else {
      item.status = item.status ? null : 'EDIT';
      setDados({ ...dados, profissoesLista: novaLista });
      filtrarLista(dados.filtro);
    }
  }

  return (
    <Container>
      <h2>Serviços </h2>
      <FlexboxGrid justify="start" style={{ gap: '10px' }}>
        <SearchTable estilo={{ width: '300px' }} filtrarLista={filtrarLista} />
        <IconButton
          style={{ marginTop: '0' }}
          icon={<Plus />}
          onClick={() => {
            setAdicionarProfissaoDialog(true);
          }}
        />
      </FlexboxGrid>
      <FlexboxGrid style={{ width: '100%' }}>
        <TabelaSemPaginacao
          carregando={carregando}
          dados={dados.profissoesListaFiltrada}
        >
          <Column resizable width={160}>
            <HeaderCell>Ativo/Inativo</HeaderCell>
            <CelulaAcaoBloquear dataKey="profissao_bloqueda" />
          </Column>

          <Column resizable width={240} sortable>
            <HeaderCell>Serviço</HeaderCell>
            <CelulaEditavel dataKey="profissao" onChange={alterarTextoCelula} />
          </Column>

          <Column resizable width={240} sortable>
            <HeaderCell>Valor a Cobrar</HeaderCell>
            <CelulaEditavel
              dataKey="valor_cobrar"
              onChange={alterarTextoCelula}
            />
          </Column>

          <Column resizable width={240} sortable>
            <HeaderCell>Valor a repassar</HeaderCell>
            <CelulaEditavel
              dataKey="valor_repassar"
              onChange={alterarTextoCelula}
            />
          </Column>

          <Column resizable width={240} sortable>
            <HeaderCell>Imagem</HeaderCell>
            <CelulaEditavel
              dataKey="valor_repassar"
              onChange={alterarTextoCelula}
            />
          </Column>

          <Column resizable width={100}>
            <HeaderCell>Icone</HeaderCell>
            <CelulaAcao
              dataKey="id"
              onClick={(id) => {
                const profissao = dados.profissoesListaFiltrada.find(
                  (p) => p.id === id
                );
                setAdicionarIconeDialog({
                  exibir: true,
                  profissao,
                });
              }}
            />
          </Column>

          <Column resizable width={200}>
            <HeaderCell>Editar</HeaderCell>
            <CelulaAcao
              dataKey="id"
              onClick={alterarEstadoCelula}
              onDelete={abrirApagarDialog}
            />
          </Column>
        </TabelaSemPaginacao>
      </FlexboxGrid>
      <Modal
        backdrop="static"
        open={adicionarProfissaoDialog}
        onClose={() => setAdicionarProfissaoDialog(false)}
        size="xs"
      >
        <Form
          model={modelo}
          onChange={setCamposInput}
          onSubmit={(status) => status && adicionarProfissao()}
        >
          <Modal.Header>
            <h3>Adicionar Serviço</h3>
          </Modal.Header>
          <Modal.Body>
            <Form.Group>
              <Form.ControlLabel>Serviço</Form.ControlLabel>
              <Form.Control name="profissao" />
            </Form.Group>
            <Form.Group>
              <Form.ControlLabel>Valor a Cobrar</Form.ControlLabel>
              <Form.Control
                name="valor_cobrar"
                accepter={InputNumber}
                step={1.0}
                min={1}
              />
            </Form.Group>
            <Form.Group>
              <Form.ControlLabel>Valor a Repassar</Form.ControlLabel>
              <Form.Control
                name="valor_repassar"
                accepter={InputNumber}
                step={1.0}
                min={1}
              />
            </Form.Group>
            <Form.Group>
              <Form.ControlLabel>Habilidade</Form.ControlLabel>
              <Form.Control name="habilidade" />
            </Form.Group>
          </Modal.Body>

          <Modal.Footer>
            <Button
              onClick={() => setAdicionarProfissaoDialog(false)}
              appearance="subtle"
            >
              Cancelar
            </Button>
            <ButtonFullBlue type="submit" appearance="primary">
              Adicionar
            </ButtonFullBlue>
          </Modal.Footer>
        </Form>
      </Modal>

      <Modal
        backdrop="static"
        open={apagarProfissaoDialog.exibir}
        onClose={fecharApagarDialog}
        size="xs"
      >
        <Modal.Header>
          <h3>Apagar Serviço</h3>
        </Modal.Header>
        <Modal.Body>
          Você realmente deseja apagar este serviço? <br /> <br />
          id: {apagarProfissaoDialog.profissao.id} <br />
          serviço: {apagarProfissaoDialog.profissao.profissao} <br />
          Essa ação é irreversível{' '}
          <RemindFill
            style={{
              color: '#ffb300',
              fontSize: 24,
            }}
          />
        </Modal.Body>

        <Modal.Footer>
          <Button onClick={fecharApagarDialog} appearance="subtle">
            Cancelar
          </Button>
          <ButtonFullBlue
            onClick={() => excluirProfissao()}
            appearance="primary"
          >
            Deletar
          </ButtonFullBlue>
        </Modal.Footer>
      </Modal>

      <AdicionarIconeDialog
        exibir={adicionarIconeDialog.exibir}
        onFechar={() => {
          setAdicionarIconeDialog({
            exibir: false,
            profissao: {},
          });
        }}
        profissao={adicionarIconeDialog.profissao}
      />
    </Container>
  );
}

Profissoes.propTypes = {
  rowData: PropTypes.func.isRequired,
};

export default Profissoes;
