/* eslint-disable prettier/prettier */
import React, { useCallback, useEffect } from 'react';
import { Switch, Route } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import { decode } from 'jsonwebtoken';
import Menu from '~/components/Menu';
import ListaProfissionais from '~/pages/Profissionais';
import ListaProfissionalBloqueado from '~/pages/Profissionais/ListaProfissionalBloqueado';
import ListaEstabelecimentoBloqueado from '~/pages/Estabelecimentos/ListaEstabelecimentoBloqueado';
import CriarProfissional from '~/pages/Profissionais/CriarProfissional';
import EditarProfissional from '~/pages/Profissionais/EditarProfissional';
import ListaEstabelecimentos from '~/pages/Estabelecimentos';
import CriarEstabelecimento from '~/pages/Estabelecimentos/CriarEstabelecimento';
import EditarEstabelecimento from '~/pages/Estabelecimentos/EditarEstabelecimento';
import ListaPedidos from '~/pages/Pedidos';
import CriarPedido from '~/pages/Pedidos/CriarPedido';
import RegistroDia from '~/pages/Pedidos/RegistrosDoDia';
import ImportarPedidos from '~/pages/Pedidos/ImportarPedidos';
import ListaRecrutamentoESelecao from '~/pages/RecrutamentoSelecao';
import CriarRecrutamentoESelecao from '~/pages/RecrutamentoSelecao/CriarRecrutamento';
import ListaIdiomas from '~/pages/opcoes/Idioma';
import ListaGeneros from '~/pages/opcoes/Genero';
import ListaComoDescobriu from '~/pages/opcoes/ComoDescobriu';
import listaProfissoes from '~/pages/opcoes/Profissoes';
import ListaTiposEstabelecimentos from '~/pages/opcoes/TiposEstabelecimentos';
import ListaEscolaridades from '~/pages/opcoes/Escolaridades';
import ListaTempoExperiencia from '~/pages/opcoes/TempoExperiencia';
import ListaHabilidades from '~/pages/opcoes/Habilidades';
import ListaDiasFuncionamento from '~/pages/opcoes/DiaFuncionamento';
import ListaPeriodosFuncionamento from '~/pages/opcoes/PeriodoFuncionamento';
import ListaUniformes from '~/pages/opcoes/Uniformes';
import RelatoriosProfissional from '~/pages/relatorios/RelatoriosProfissional';
import RelatorioProfissionaisEmergencia from '~/pages/relatorios/RelatorioProfissionaisEmergencia';
import RelatoriosEstabelecimento from '~/pages/relatorios/RelatoriosEstabelecimento';
import PedidosCancelados from '~/pages/relatorios/PedidosCancelados';
import Dashboard from '~/pages/relatorios/Dashboard';
import CarteiraClientes from '~/pages/relatorios/CarteiraClientes';
import CarteiraProfissionais from '~/pages/relatorios/CarteiraProfissionais';
// eslint-disable-next-line import/no-named-as-default, import/no-named-as-default-member
import TrabalhosSemana from '~/pages/relatorios/TrabalhosSemana';
import Metricas from '~/pages/relatorios/Metricas';
import ClientesAtivados from '~/pages/relatorios/ClientesAtivados';
import ClientesInadimplentes from '~/pages/relatorios/ClientesInadimplentes';
import RelatorioNotificacoes from '~/pages/relatorios/RelatorioNotificacoes';
import RelatorioProfissionaisDisponiveis from '~/pages/relatorios/RelatorioProfissionaisDisponiveis';
import AnaliseRecorrencia from '~/pages/relatorios/AnaliseRecorrencia';
import ListaPipeline from '~/pages/RecrutamentoSelecao/ListaPipeline';
import { deslogar } from '~/store/modulos/autenticacao/actions';
import {
  buscarNotificacoes,
  verificarTokenNotificacao,
} from '~/store/modulos/notificacao/actions';
import api from '~/services/api';
import { states, permissions, userData } from '~/store/modulos/global/actions';
import { store } from '~/store';
import IuguIndex from '../Iugu/index';
import StarkIndex from '../Stark/index';
import Cupons from '../opcoes/Cupons';
import Estado from '../Tarifas/Estado';
import TarifasFeriados from '../Tarifas/TarifasFeriados';
import TarifasRepasses from '../Tarifas/TarifasRepasses';
import Notificacoes from '../Mensagens';
import NovoProfissional from '../Profissionais/NovoProfissional';
import NovoEstabelecimento from '../Estabelecimentos/NovoEstabelecimento';
import LocalizacaoRecebida from '../Alertas/LocalizacaoRecebida';
import FalhaCobranca from '../Iugu/FalhaCobranca';
import AlteracaoPedido from '../Pedidos/AlteracaoPedido';
import PedidoRecebido from '../Pedidos/PedidoRecebido';
import PedidoCancelado from '../Pedidos/PedidoCancelado';
import EnviarNotificacaoProfissional from '../Mensagens/EnviarNotificacaoProfissional';
import EnviarNotificacaoEstabelecimento from '../Mensagens/EnviarNotificacaoEstabelecimento';
import Treinamentos from '../opcoes/Treinamentos';
import Parcerias from '../opcoes/Parcerias';
import MotivosCancelamento from '../opcoes/MotivosCancelamento';
import AnnouncementsProfessionalList from '../Mensagens/AnnouncementsProfessionalList';
import AnnouncementsEstablishmentList from '../Mensagens/AnnouncementsEstablishmentList';
import CriarUsuarios from '../Administracao/Usuarios';
import Logs from '../Administracao/Logs';

import NaoAutorizado from '../NaoAutorizado';
import Usuario from '../Usuario';
import ControleMatchings from '../Administracao/Controle';
import EnviarPopUp from '../Mensagens/EnviarPopUp';
import ListaPopups from '../Mensagens/ListaPopups';
import RetornoConfirmacoes from '../Pedidos/RetornoConfirmacoes';
import EscalaFixa from '../Pedidos/EscalaFixa';

import CadidatosGerais from '../RecrutamentoSelecao/CandidatosGerais';
import LiberacaoCadastroAdmin from '../Administracao/LiberacaoCadastroAdmin';
import QuantidadeColaboradoresOpcoes from '../opcoes/QtdColaboradores';

function Home() {
  const dispatch = useDispatch();

  const permissionsList = useSelector((state) => state.permissions.permissions);

  const permissoes = permissionsList && {
    profissionais:
      permissionsList.profissionais && permissionsList.profissionais.split(','),
    estabelecimentos:
      permissionsList.estabelecimentos &&
      permissionsList.estabelecimentos.split(','),
    pedidos: permissionsList.pedidos && permissionsList.pedidos.split(','),
    opcoes: permissionsList.opcoes && permissionsList.opcoes.split(','),
    relatorios:
      permissionsList.relatorios && permissionsList.relatorios.split(','),
    iugu: permissionsList.iugu && permissionsList.iugu.split(','),
    stark: permissionsList.stark && permissionsList.stark.split(','),
    notificacoes:
      permissionsList.notificacoes && permissionsList.notificacoes.split(','),
    administracao:
      permissionsList.administracao && permissionsList.administracao.split(','),
    tarifas: permissionsList.tarifas && permissionsList.tarifas.split(','),
    recrutamento:
      permissionsList.recrutamento && permissionsList.recrutamento.split(','),
  };

  const getStates = useCallback(async () => {
    await api.get('estado').then((response) => {
      dispatch(states(response.data));
    });
  }, [dispatch]);
  console.log(permissionsList, 'permissionsList')

  const setPermissions = useCallback(async () => {
    const token = localStorage.getItem('token-login');
    if (!token) {
      store.dispatch(deslogar());
    }
    const decodedToken = decode(token);
    console.log(decodedToken, 'decodedToken')
    dispatch(permissions(decodedToken.permissions));
    await api
      .get(`/usuario/role/${decodedToken.id}`)
      .then((response) => {
        if (response.data.user.role !== decodedToken.role) {
          store.dispatch(deslogar());
        }
      })
      .catch(() => {
        store.dispatch(deslogar());
      });
  }, [dispatch]);

  const setUser = useCallback(() => {
    const token = localStorage.getItem('token-login');
    const decodedToken = decode(token);
    dispatch(
      userData({
        id: decodedToken.id,
        nome: decodedToken.nome,
        email: decodedToken.email,
        role: decodedToken.role,
      })
    );
  }, [dispatch]);

  useEffect(() => {
    dispatch(verificarTokenNotificacao());
    dispatch(buscarNotificacoes({ pagina: 1 }));
    getStates();
    setPermissions();
    setUser();
  }, [dispatch, getStates, setPermissions, setUser]);

  return (
    <div style={{ display: 'flex', height: '100%' }}>
      <Menu />
      <Switch>
        <Route
          path="/home/profissionais"
          exact
          component={
            permissionsList &&
              permissoes.profissionais &&
              (permissoes.profissionais.includes('pro-1') ||
                permissoes.profissionais.includes('pro-all'))
              ? ListaProfissionais
              : NaoAutorizado
          }
        />
        <Route
          path="/home/profissionais/bloqueados"
          exact
          component={
            permissionsList &&
              permissoes.profissionais &&
              (permissoes.profissionais.includes('pro-2') ||
                permissoes.profissionais.includes('pro-all'))
              ? ListaProfissionalBloqueado
              : NaoAutorizado
          }
        />
        <Route
          path="/home/profissionais/criar"
          exact
          component={
            permissionsList &&
              permissoes.profissionais &&
              (permissoes.profissionais.includes('pro-3') ||
                permissoes.profissionais.includes('pro-all'))
              ? CriarProfissional
              : NaoAutorizado
          }
        />
        <Route
          path="/home/profissionais/editar/:id"
          component={EditarProfissional}
        />
        <Route
          path="/home/profissionais/disponiveis"
          component={
            permissionsList &&
              permissoes.profissionais &&
              (permissoes.profissionais.includes('pro-5') ||
                permissoes.profissionais.includes('pro-all'))
              ? RelatorioProfissionaisDisponiveis
              : NaoAutorizado
          }
        />
        <Route
          path="/home/profissionais/emergencia"
          component={
            permissionsList &&
              permissoes.profissionais &&
              (permissoes.profissionais.includes('pro-5') ||
                permissoes.profissionais.includes('pro-all'))
              ? RelatorioProfissionaisEmergencia
              : NaoAutorizado
          }
        />
        <Route
          path="/home/estabelecimentos"
          exact
          component={
            permissionsList &&
              permissoes.estabelecimentos &&
              (permissoes.estabelecimentos.includes('estab-1') ||
                permissoes.estabelecimentos.includes('estab-all'))
              ? ListaEstabelecimentos
              : NaoAutorizado
          }
        />
        <Route
          path="/home/estabelecimentos/bloqueados"
          exact
          component={
            permissionsList &&
              permissoes.estabelecimentos &&
              (permissoes.estabelecimentos.includes('estab-2') ||
                permissoes.estabelecimentos.includes('estab-all'))
              ? ListaEstabelecimentoBloqueado
              : NaoAutorizado
          }
        />
        <Route
          path="/home/estabelecimentos/criar"
          exact
          component={
            permissionsList &&
              permissoes.estabelecimentos &&
              (permissoes.estabelecimentos.includes('estab-3') ||
                permissoes.estabelecimentos.includes('estab-all'))
              ? CriarEstabelecimento
              : NaoAutorizado
          }
        />
        <Route
          path="/home/estabelecimentos/editar/:id"
          exact
          component={EditarEstabelecimento}
        />
        <Route
          path="/home/pedidos"
          exact
          component={
            permissionsList &&
              permissoes.pedidos &&
              (permissoes.pedidos.includes('ped-1') ||
                permissoes.pedidos.includes('ped-all'))
              ? ListaPedidos
              : NaoAutorizado
          }
        />
        <Route
          path="/home/pedidos/criar"
          exact
          component={
            permissionsList &&
              permissoes.pedidos &&
              (permissoes.pedidos.includes('ped-2') ||
                permissoes.pedidos.includes('ped-all'))
              ? CriarPedido
              : NaoAutorizado
          }
        />
        <Route
          path="/home/pedidos/registros-dia"
          exact
          component={
            permissionsList &&
              permissoes.pedidos &&
              (permissoes.pedidos.includes('ped-4') ||
                permissoes.pedidos.includes('ped-all'))
              ? RegistroDia
              : NaoAutorizado
          }
        />
        <Route
          path="/home/pedidos/retorno-confirmacoes"
          exact
          component={
            permissionsList &&
              permissoes.pedidos &&
              (permissoes.pedidos.includes('ped-4') ||
                permissoes.pedidos.includes('ped-all'))
              ? RetornoConfirmacoes
              : NaoAutorizado
          }
        />
        <Route
          path="/home/pedidos/escala-fixa"
          exact
          component={
            permissionsList &&
              permissoes.pedidos &&
              (permissoes.pedidos.includes('ped-7') ||
                permissoes.pedidos.includes('ped-all'))
              ? EscalaFixa
              : NaoAutorizado
          }
        />
        <Route
          path="/home/importar-pedidos"
          exact
          component={ImportarPedidos}
        />
        {/* recrutamento e seleção */}
        <Route
          path="/home/recrutamento-selecao"
          exact
          component={
            permissionsList &&
              permissoes.recrutamento &&
              (permissoes.recrutamento.includes('recrut-1') ||
                permissoes.recrutamento.includes('recrut-all'))
              ? ListaRecrutamentoESelecao
              : NaoAutorizado
          }
        />
        <Route
          path="/home/recrutamento-selecao/criar"
          exact
          component={
            permissionsList &&
              permissoes.recrutamento &&
              (permissoes.recrutamento.includes('recrut-1') ||
                permissoes.recrutamento.includes('recrut-all'))
              ? CriarRecrutamentoESelecao
              : NaoAutorizado
          }
        />
        <Route
          path="/home/recrutamento-lista"
          exact
          component={
            permissionsList &&
              permissoes.recrutamento &&
              (permissoes.recrutamento.includes('recrut-1') ||
                permissoes.recrutamento.includes('recrut-all'))
              ? ListaPipeline
              : NaoAutorizado
          }
        />
        <Route
          path="/home/recrutamento-cadidatos-gerais"
          exact
          component={
            permissionsList &&
              permissoes.recrutamento &&
              (permissoes.recrutamento.includes('recrut-1') ||
                permissoes.recrutamento.includes('recrut-all'))
              ? CadidatosGerais
              : NaoAutorizado
          }
        />

        <Route
          path="/home/idiomas"
          exact
          component={
            permissionsList &&
              permissoes.opcoes &&
              (permissoes.opcoes.includes('op-1') ||
                permissoes.opcoes.includes('op-all'))
              ? ListaIdiomas
              : NaoAutorizado
          }
        />

        <Route
          path="/home/generos"
          exact
          component={
            permissionsList &&
              permissoes.opcoes &&
              (permissoes.opcoes.includes('op-4') ||
                permissoes.opcoes.includes('op-all'))
              ? ListaGeneros
              : NaoAutorizado
          }
        />

        <Route
          path="/home/ComoDescobriu"
          exact
          component={
            permissionsList &&
              permissoes.opcoes &&
              (permissoes.opcoes.includes('op-2') ||
                permissoes.opcoes.includes('op-all'))
              ? ListaComoDescobriu
              : NaoAutorizado
          }
        />
        <Route
          path="/home/quantidade-colaboradores"
          exact
          component={
            permissionsList &&
              permissoes.opcoes &&
              (permissoes.opcoes.includes('op-3') ||
                permissoes.opcoes.includes('op-all'))
              ? QuantidadeColaboradoresOpcoes
              : NaoAutorizado
          }
        />
        <Route
          path="/home/profissoes"
          exact
          component={
            permissionsList &&
              permissoes.opcoes &&
              (permissoes.opcoes.includes('op-5') ||
                permissoes.opcoes.includes('op-all'))
              ? listaProfissoes
              : NaoAutorizado
          }
        />
        <Route
          path="/home/tipos-estabelecimentos"
          exact
          component={
            permissionsList &&
              permissoes.opcoes &&
              (permissoes.opcoes.includes('op-6') ||
                permissoes.opcoes.includes('op-all'))
              ? ListaTiposEstabelecimentos
              : NaoAutorizado
          }
        />
        <Route
          path="/home/escolaridades"
          exact
          component={
            permissionsList &&
              permissoes.opcoes &&
              (permissoes.opcoes.includes('op-7') ||
                permissoes.opcoes.includes('op-all'))
              ? ListaEscolaridades
              : NaoAutorizado
          }
        />
        <Route
          path="/home/tempo-experiencias"
          exact
          component={
            permissionsList &&
              permissoes.opcoes &&
              (permissoes.opcoes.includes('op-8') ||
                permissoes.opcoes.includes('op-all'))
              ? ListaTempoExperiencia
              : NaoAutorizado
          }
        />
        <Route
          path="/home/habilidades"
          exact
          component={
            permissionsList &&
              permissoes.opcoes &&
              (permissoes.opcoes.includes('op-9') ||
                permissoes.opcoes.includes('op-all'))
              ? ListaHabilidades
              : NaoAutorizado
          }
        />
        <Route
          path="/home/dias-funcionamento"
          exact
          component={
            permissionsList &&
              permissoes.opcoes &&
              (permissoes.opcoes.includes('op-10') ||
                permissoes.opcoes.includes('op-all'))
              ? ListaDiasFuncionamento
              : NaoAutorizado
          }
        />
        <Route
          path="/home/periodos-funcionamento"
          exact
          component={
            permissionsList &&
              permissoes.opcoes &&
              (permissoes.opcoes.includes('op-11') ||
                permissoes.opcoes.includes('op-all'))
              ? ListaPeriodosFuncionamento
              : NaoAutorizado
          }
        />
        <Route
          path="/home/uniformes"
          exact
          component={
            permissionsList &&
              permissoes.opcoes &&
              (permissoes.opcoes.includes('op-12') ||
                permissoes.opcoes.includes('op-all'))
              ? ListaUniformes
              : NaoAutorizado
          }
        />
        <Route
          path="/home/relatorios/profissional"
          exact
          component={
            permissionsList &&
              permissoes.relatorios &&
              (permissoes.relatorios.includes('rel-1') ||
                permissoes.relatorios.includes('rel-all'))
              ? RelatoriosProfissional
              : NaoAutorizado
          }
        />
        <Route
          path="/home/relatorios/estabelecimento"
          exact
          component={
            permissionsList &&
              permissoes.relatorios &&
              (permissoes.relatorios.includes('rel-2') ||
                permissoes.relatorios.includes('rel-all'))
              ? RelatoriosEstabelecimento
              : NaoAutorizado
          }
        />
        <Route
          path="/home/relatorios/pedidos-cancelados"
          exact
          component={
            permissionsList &&
              permissoes.relatorios &&
              (permissoes.relatorios.includes('rel-8') ||
                permissoes.relatorios.includes('rel-all'))
              ? PedidosCancelados
              : NaoAutorizado
          }
        />
        <Route
          path="/home/relatorios/notificacoes"
          exact
          component={
            permissionsList &&
              permissoes.relatorios &&
              (permissoes.relatorios.includes('rel-7') ||
                permissoes.relatorios.includes('rel-all'))
              ? RelatorioNotificacoes
              : NaoAutorizado
          }
        />
        <Route
          path="/home/relatorios/dashboard"
          exact
          component={
            permissionsList &&
              permissoes.relatorios &&
              (permissoes.relatorios.includes('rel-4') ||
                permissoes.relatorios.includes('rel-all'))
              ? Dashboard
              : NaoAutorizado
          }
        />
        <Route
          path="/home/relatorios/carteira-clientes"
          exact
          component={
            permissionsList &&
              permissoes.estabelecimentos &&
              (permissoes.estabelecimentos.includes('estab-4') ||
                permissoes.estabelecimentos.includes('estab-all'))
              ? CarteiraClientes
              : NaoAutorizado
          }
        />
        <Route
          path="/home/relatorios/carteira-profissionais"
          exact
          component={
            permissionsList &&
              permissoes.profissionais &&
              (permissoes.profissionais.includes('pro-4') ||
                permissoes.profissionais.includes('pro-all'))
              ? CarteiraProfissionais
              : NaoAutorizado
          }
        />
        <Route
          path="/home/relatorios/trabalhos-semana"
          exact
          component={
            permissionsList &&
              permissoes.profissionais &&
              (permissoes.profissionais.includes('pro-8') ||
                permissoes.profissionais.includes('pro-all'))
              ? TrabalhosSemana
              : NaoAutorizado
          }
        />
        <Route
          path="/home/relatorios/metricas"
          exact
          component={
            permissionsList &&
              permissoes.relatorios &&
              (permissoes.relatorios.includes('rel-5') ||
                permissoes.relatorios.includes('rel-all'))
              ? Metricas
              : NaoAutorizado
          }
        />
        {/* Iugu */}
        <Route
          path="/home/iugu/index"
          exact
          component={
            permissionsList &&
              permissoes.iugu &&
              (permissoes.iugu.includes('iugu-1') ||
                permissoes.iugu.includes('iugu-all'))
              ? IuguIndex
              : NaoAutorizado
          }
        />
        <Route
          path="/home/alertas/falha-cobranca"
          exact
          component={
            permissionsList &&
              permissoes.iugu &&
              (permissoes.iugu.includes('iugu-2') ||
                permissoes.iugu.includes('iugu-all'))
              ? FalhaCobranca
              : NaoAutorizado
          }
        />
        {/* Stark */}
        <Route
          path="/home/stark/index"
          exact
          component={StarkIndex}
        // component={
        //   permissionsList &&
        //     permissoes.stark &&
        //     (permissoes.stark.includes('stark-1') ||
        //       permissoes.stark.includes('stark-all'))
        //     ? StarkIndex
        //     : NaoAutorizado
        // }
        />

        <Route
          path="/home/relatorios/clientes-ativados"
          exact
          component={
            permissionsList &&
              permissoes.relatorios &&
              (permissoes.relatorios.includes('rel-6') ||
                permissoes.relatorios.includes('rel-all'))
              ? ClientesAtivados
              : NaoAutorizado
          }
        />

        <Route
          path="/home/relatorios/clientes-inadimplentes"
          exact
          component={
            permissionsList &&
              permissoes.relatorios &&
              (permissoes.relatorios.includes('rel-8') ||
                permissoes.relatorios.includes('rel-all'))
              ? ClientesInadimplentes
              : NaoAutorizado
          }
        />
        <Route
          path="/home/relatorios/recorrencia"
          exact
          component={
            permissionsList &&
              permissoes.relatorios &&
              (permissoes.relatorios.includes('rel-9') ||
                permissoes.relatorios.includes('rel-all'))
              ? AnaliseRecorrencia
              : NaoAutorizado
          }
        />
        <Route
          path="/home/cupons"
          exact
          component={
            permissionsList &&
              permissoes.opcoes &&
              (permissoes.opcoes.includes('op-13') ||
                permissoes.opcoes.includes('op-all'))
              ? Cupons
              : NaoAutorizado
          }
        />
        <Route
          path="/home/treinamentos"
          exact
          component={
            permissionsList &&
              permissoes.opcoes &&
              (permissoes.opcoes.includes('op-14') ||
                permissoes.opcoes.includes('op-all'))
              ? Treinamentos
              : NaoAutorizado
          }
        />
        <Route
          path="/home/motivo-cancelamento"
          exact
          component={
            permissionsList &&
              permissoes.opcoes &&
              (permissoes.opcoes.includes('op-15') ||
                permissoes.opcoes.includes('op-all'))
              ? MotivosCancelamento
              : NaoAutorizado
          }
        />
        <Route
          path="/home/parcerias"
          exact
          component={
            permissionsList &&
              permissoes.opcoes &&
              (permissoes.opcoes.includes('op-16') ||
                permissoes.opcoes.includes('op-all'))
              ? Parcerias
              : NaoAutorizado
          }
        />
        <Route
          path="/home/estado"
          exact
          component={
            permissionsList &&
              permissoes.tarifas &&
              (permissoes.tarifas.includes('tarif-1') ||
                permissoes.tarifas.includes('tarif-all'))
              ? Estado
              : NaoAutorizado
          }
        />
        <Route
          path="/home/tarifa-feriado"
          exact
          component={
            permissionsList &&
              permissoes.tarifas &&
              (permissoes.tarifas.includes('tarif-2') ||
                permissoes.tarifas.includes('tarif-all'))
              ? TarifasFeriados
              : NaoAutorizado
          }
        />
        {/* <Route
          path="/home/tarifa-repasse"
          exact
          component={
            permissionsList &&
              permissoes.tarifas &&
              (permissoes.tarifas.includes('tarif-3') ||
                permissoes.tarifas.includes('tarif-all'))
              ? TarifasRepasses
              : NaoAutorizado
          }
        /> */}
        <Route
          path="/home/notificacoes/lista"
          exact
          component={
            permissionsList &&
              permissoes.notificacoes &&
              (permissoes.notificacoes.includes('noti-1') ||
                permissoes.notificacoes.includes('noti-all'))
              ? Notificacoes
              : NaoAutorizado
          }
        />
        <Route
          path="/home/popup"
          exact
          component={
            permissionsList &&
              permissoes.notificacoes &&
              (permissoes.notificacoes.includes('noti-1') ||
                permissoes.notificacoes.includes('noti-all'))
              ? EnviarPopUp
              : NaoAutorizado
          }
        />
        <Route
          path="/home/popup/list"
          exact
          component={
            permissionsList &&
              permissoes.notificacoes &&
              (permissoes.notificacoes.includes('noti-2') ||
                permissoes.notificacoes.includes('noti-all'))
              ? ListaPopups
              : NaoAutorizado
          }
        />
        <Route
          path="/home/notificacoes/profissionais"
          exact
          component={
            permissionsList &&
              permissoes.notificacoes &&
              (permissoes.notificacoes.includes('noti-3') ||
                permissoes.notificacoes.includes('noti-all'))
              ? EnviarNotificacaoProfissional
              : NaoAutorizado
          }
        />
        <Route
          path="/home/notificacoes/estabelecimentos"
          exact
          component={
            permissionsList &&
              permissoes.notificacoes &&
              (permissoes.notificacoes.includes('noti-5') ||
                permissoes.notificacoes.includes('noti-all'))
              ? EnviarNotificacaoEstabelecimento
              : NaoAutorizado
          }
        />
        <Route
          path="/home/announcements/professional/list"
          exact
          component={
            permissionsList &&
              permissoes.notificacoes &&
              (permissoes.notificacoes.includes('noti-4') ||
                permissoes.notificacoes.includes('noti-all'))
              ? AnnouncementsProfessionalList
              : NaoAutorizado
          }
        />
        <Route
          path="/home/announcements/estabelecimento/list"
          exact
          component={
            permissionsList &&
              permissoes.notificacoes &&
              (permissoes.notificacoes.includes('noti-6') ||
                permissoes.notificacoes.includes('noti-all'))
              ? AnnouncementsEstablishmentList
              : NaoAutorizado
          }
        />

        <Route
          path="/home/alertas/novo-profissional"
          exact
          component={
            permissionsList &&
              permissoes.notificacoes &&
              (permissoes.notificacoes.includes('noti-1') ||
                permissoes.notificacoes.includes('noti-all'))
              ? NovoProfissional
              : NaoAutorizado
          }
        />
        <Route
          path="/home/alertas/novo-estabelecimento"
          exact
          component={
            permissionsList &&
              permissoes.notificacoes &&
              (permissoes.notificacoes.includes('noti-1') ||
                permissoes.notificacoes.includes('noti-all'))
              ? NovoEstabelecimento
              : NaoAutorizado
          }
        />
        <Route
          path="/home/alertas/localizacao-recebida"
          exact
          component={
            permissionsList &&
              permissoes.notificacoes &&
              (permissoes.notificacoes.includes('noti-1') ||
                permissoes.notificacoes.includes('noti-all'))
              ? LocalizacaoRecebida
              : NaoAutorizado
          }
        />
        <Route
          path="/home/alertas/alteracao-pedido"
          exact
          component={
            permissionsList &&
              permissoes.notificacoes &&
              (permissoes.notificacoes.includes('noti-1') ||
                permissoes.notificacoes.includes('noti-all'))
              ? AlteracaoPedido
              : NaoAutorizado
          }
        />
        <Route
          path="/home/alertas/pedido-recebido"
          exact
          component={
            permissionsList &&
              permissoes.notificacoes &&
              (permissoes.notificacoes.includes('noti-1') ||
                permissoes.notificacoes.includes('noti-all'))
              ? PedidoRecebido
              : NaoAutorizado
          }
        />
        <Route
          path="/home/alertas/pedido-cancelado"
          exact
          component={
            permissionsList &&
              permissoes.notificacoes &&
              (permissoes.notificacoes.includes('noti-1') ||
                permissoes.notificacoes.includes('noti-all'))
              ? PedidoCancelado
              : NaoAutorizado
          }
        />

        <Route
          path="/home/administracao/usuarios"
          exact
          component={
            permissionsList &&
              permissoes.administracao &&
              (permissoes.administracao.includes('adm-1') ||
                permissoes.administracao.includes('adm-all'))
              ? CriarUsuarios
              : NaoAutorizado
          }
        />
        <Route
          path="/home/administracao/logs"
          exact
          component={
            permissionsList &&
              permissoes.administracao &&
              (permissoes.administracao.includes('adm-1') ||
                permissoes.administracao.includes('adm-all'))
              ? Logs
              : NaoAutorizado
          }
        />
        <Route
          path="/home/administracao/controle"
          exact
          component={
            permissionsList &&
              permissoes.administracao &&
              (permissoes.administracao.includes('adm-1') ||
                permissoes.administracao.includes('adm-all'))
              ? ControleMatchings
              : NaoAutorizado
          }
        />
        <Route
          path="/home/administracao/controle-liberacao"
          exact
          component={
            permissionsList &&
              permissoes.administracao &&
              (permissoes.administracao.includes('adm-1') ||
                permissoes.administracao.includes('adm-all'))
              ? LiberacaoCadastroAdmin
              : NaoAutorizado
          }
        />
        <Route path="/home/usuario" exact component={Usuario} />
      </Switch>
    </div>
  );
}

export default Home;
