/* eslint-disable no-unused-expressions */
/* eslint-disable react/prop-types */
import React from 'react';
import { Form, Input, Checkbox } from 'rsuite';
import PropTypes from 'prop-types';
import { Responsive } from './styles';

export function ResponsiveForm(props) {
  return (
    <Responsive>
      <Form.Group
        // eslint-disable-next-line react/destructuring-assignment
        style={props.estilo && { display: 'flex', marginTop: '10px' }}
      >
        {/* eslint-disable-next-line react/destructuring-assignment */}
        <Form.ControlLabel style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}>{props.label}</Form.ControlLabel>
        {/* eslint-disable-next-line react/jsx-props-no-spreading, react/destructuring-assignment */}
        <Form.Control {...props} placeholder={props.placeholder} inputRef={props.ref} />
      </Form.Group>
    </Responsive>
  );
}

export function ResponsiveFormNoNumber({ setFiltroNumero, alteraValorInput, filtroNumero, label, estilo, name, formValor, disabled }) {
  return (
    <Responsive>
      <Form.Group
        // eslint-disable-next-line react/destructuring-assignment
        style={estilo && { display: 'flex', marginTop: '10px' }}
      >
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          {/* eslint-disable-next-line react/destructuring-assignment */}
          <Form.ControlLabel style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}>{label}</Form.ControlLabel>
          <Checkbox
            style={{ margin: '0px', padding: '0px', whiteSpace: 'nowrap', marginRight: '26px' }}
            // eslint-disable-next-line react/destructuring-assignment
            value={filtroNumero}
            title="Filtro sem número"
            onChange={(a, valor) => {
              setFiltroNumero(valor)
              alteraValorInput(valor)
            }}
          >
            Sem Número
          </Checkbox>
        </div>
        {/* eslint-disable-next-line react/jsx-props-no-spreading, react/destructuring-assignment */}
        <Form.Control name={name} value={formValor} disabled={disabled} />
      </Form.Group>
    </Responsive>
  );
}
const Textarea = React.forwardRef((props, ref) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Input style={{ width: '382px' }} {...props} as="textarea" ref={ref} />
));

export function ResponsiveFormTextArea(props) {
  return (
    <Responsive>
      <Form.Group
        // eslint-disable-next-line react/destructuring-assignment
        style={props.estilo && { display: 'flex', marginTop: '10px' }}
      >
        {/* eslint-disable-next-line react/destructuring-assignment */}
        <Form.ControlLabel>{props.label}</Form.ControlLabel>
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <Form.Control rows={5} {...props} accepter={Textarea} />
      </Form.Group>
    </Responsive>
  );
}

ResponsiveForm.propTypes = {
  // estilo: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};

ResponsiveFormTextArea.propTypes = {
  // estilo: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};
