/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-nested-ternary */
import React, { useState, useCallback, useEffect, useContext } from 'react'
import JsBarcode from 'jsbarcode';
import QRCode from 'qrcode'
import { Container } from '~/styles/tabela'
import api from '~/services/api'
import useSearchNames from '~/services/useSearchNames';
import { switchLogo } from '~/assets/SwitchLogo';
import {
  FlexboxGrid,
  Table,
  InputGroup,
  Input,
  CheckPicker,
  Button,
  Modal,
  AutoComplete
} from 'rsuite'
import { toast } from 'react-toastify';

import { InputData } from '~/components/InputData';
import moment from 'moment';
import JSPDF from 'jspdf';
import SearchIcon from '@rsuite/icons/Search';
import { InputDataRange } from '~/components/InputDataRange';
// import { TabelaSemPaginacao } from '~/components/TabelaSemPaginacao'
import formatCurrency from '~/utils/formatCurrency'
import { DarkModeContext } from '~/common/contexts/darkMode';
import { format, parseISO, addDays, isBefore, subDays } from 'date-fns'
import DropDownCell from './components/DropDownCell';
import { TablePagination } from '../../components/TablePagination';
import { CelulaFormatada } from './components/CelulaFormatada'
import GerarFaturaStark from './components/GerarFaturaStark'
import GerarFaturasSemanais from './components/GerarFaturasSemanais'
import { CelulaAcaoBaixarPdf } from '../relatorios/RelatoriosEstabelecimento/components/CelulaAcaoBaixarPdf';


const { HeaderCell, Column } = Table;

export default function StarkIndex() {

  const { toggleMode } = useContext(DarkModeContext)
  const [carregando, setCarregando] = useState(false)
  const [pagina, setPagina] = useState(1);
  const [faturas, setFaturas] = useState([])
  const [relatorioRowData, setRelatorioRowData] = useState([])
  const [orderBy, setOrderBy] = useState('');
  // const [limit, setLimit] = useState();
  const [datas, setDatas] = useState([])
  const [nomesEstabelecimentos, setNomesEstabelecimentos] = useState([])
  const [estabelecimentoSelecionado, setEstabelecimentoSelecionado] = useState({
    label: '',
    value: '',
    id_stark: null
  })

  const [exibir, setExibir] = useState(false);
  const [exibirCancelar, setExibirCancelar] = useState(false);
  const [idFatura, setIdFatura] = useState('');
  const [idBoleto, setIdInvoice] = useState('');
  const [idInvoice, setIdBoleto] = useState('');
  const [pedido, setPedido] = useState();
  const [pedidoII, setPedidoII] = useState();
  const [statusFiltro, setStatusFiltro] = useState([
    'pending',
    'paid',
    'canceled',
  ]);

  const statusFatura = [
    { label: 'Pendente', value: 'pending' },
    { label: 'Pago', value: 'paid' },
    { label: 'Cancelado', value: 'canceled' },
    // { label: 'Parcialmente Pago', value: 'partially_paid' },
    // { label: 'Devolvido', value: 'refunded' },
    // { label: 'Expirado', value: 'expired' },
    // { label: 'Autorizado', value: 'authorized' },
  ]
  const today = addDays(new Date(), 2);
  const [filtroData, setFiltroData] = useState(today);

  const locale = {
    sunday: 'Dom',
    monday: 'Seg',
    tuesday: 'Ter',
    wednesday: 'Qua',
    thursday: 'Qui',
    friday: 'Sex',
    saturday: 'Sab',
    ok: 'Ok',
    today: 'Hoje',
    yesterday: 'Ontem',
    hours: 'Horas',
    minutes: 'Minutos',
    seconds: 'Segundos',
  };


  const carregarFaturas = useCallback(async () => {

    setCarregando(true)

    function statusBoleto(status_b) {
      switch (status_b) {
        case 'created':
          status_b = 'Criado'
          break
        case 'registered':
          status_b = 'Pendente'
          break
        case 'canceled':
          status_b = 'Cancelado'
          break
        case 'paid':
          status_b = 'Pago'
          break
        default: status_b = 'Pendente'

      }
      return status_b
    }
    function statusInvoice(status_v) {
      switch (status_v) {
        case 'created':
          status_v = 'Pendente'
          break
        case 'canceled':
          status_v = 'Cancelado'
          break
        case 'paid':
          status_v = 'Pago'
          break
        default: status_v = 'Pendente'

      }
      return status_v
    }
    try {


      const result = await api.get('/pagamentos/getFaturas', {
        params: {
          pedido,
          status: statusFiltro,
          pagina,
          estabelecimento: estabelecimentoSelecionado.id_stark || null,
          datas,
          orderBy
        }
      })
      console.log(result, 'result 91')

      const lista = result.data.faturas.map((map) => ({
        ...map,
        status_boleto: statusBoleto(map.status_boleto),
        status_invoice: statusInvoice(map.status_invoice),
        valor: formatCurrency(map.valor),
        created_at: format(parseISO(map.created_at), 'dd/MM/yyyy'),
        data_vencimento: format(parseISO(map.vencimento), 'dd/MM/yyyy'),
      }))
      console.log(lista)

      setFaturas({
        lista,
        quantidade: lista.length > 0 ? lista[0].full_count : 0,
      })
      if (faturas.quantidade === 0) setPagina(1)

    } catch (error) {
      console.log(error)
    }

    setCarregando(false)

  }, [pedido, orderBy, pagina, faturas.quantidade, datas, estabelecimentoSelecionado.id_stark, statusFiltro])

  async function exportPDF(relatorio) {
    setCarregando(true);
    console.log(relatorio)
    try {
      console.log("exportPDF")
      let qrCode = ''
      const { valor, vencimento, linha_digitavel, brcode, codigo_barras } = relatorio;
      const unit = 'pt';
      const size = 'A3';
      const orientation = 'landscape';

      const marginLeft = 40;
      const doc = new JSPDF(orientation, unit, size);

      const title = `- Pagamento - ${relatorio.nome_fantasia} | Total: ${valor}`;
      const brcodePix = "00020126330014BR.GOV.BCB.PIX0114+5591234567895204000053039865802BR5910Beneficiário6008BRASIL62280516...";

      qrCode = await new Promise((resolve, reject) => {
        QRCode.toDataURL(brcodePix, { errorCorrectionLevel: 'm' }, (err, url) => {
          if (err) reject(err);
          resolve(url);
        });
      });

      doc.addImage(switchLogo, 'JPEG', 40, 40, 120, 20);
      doc.text(title, 167, 56);

      doc.setFontSize(18);
      doc.setFont("Helvetica");
      doc.text("Banco: STARK BANK S.A.", 40, 80);
      doc.text("QR Code", 905, 168)
      doc.addImage(qrCode, 'PNG', 800, 170, 300, 300)
      doc.text(`Data de Vencimento: ${format(new Date(vencimento), 'dd/LL/yyyy')}`, 40, 98);
      doc.text(`Valor do Documento: ${valor}`, 40, 118);
      doc.line(35, 135, 500, 135);
      doc.rect(32, 200, 520, 30);
      doc.text("Número do Boleto:", 40, 190);
      doc.text(linha_digitavel, 40, 220);
      doc.text("Pix Copia e Cola:", 40, 550)
      doc.setFontSize(12);
      doc.text(brcode, 40, 570);
      doc.setFontSize(18);
      doc.text(`Código de Barras: ${codigo_barras}`, 40, 400);
      const canvas = document.createElement("canvas");
      JsBarcode(canvas, codigo_barras, {
        format: "CODE128",
        displayValue: false,
        height: 40
      });
      const barcodeBase64 = canvas.toDataURL("image/png");
      doc.addImage(barcodeBase64, "PNG", 30, 410, 600, 40);
      doc.setFontSize(12)
      doc.save(
        `${relatorio.nome_fantasia} - Pagamento.pdf`
      );

    } catch (error) {
      toast.error(error.message);
    }
    setCarregando(false);
  }

  useEffect(() => {
    carregarFaturas()
  }, [carregarFaturas])

  const buscarNomesEstabelecimentos = useSearchNames(
    setNomesEstabelecimentos,
    'estabelecimentos'
  );

  // async function exportPDF(relatorio) {
  //   try {

  //   } catch (error) {

  //   }
  // }

  async function cancelarFatura(id, id_invoice, id_boleto) {
    setCarregando(true);
    setExibirCancelar(false);
    await api
      .put(`/pagamentos/cancelarFatura/${id}`, {
        id_invoice,
        id_boleto,
      })
      .then(async () => {
        await carregarFaturas()
        setCarregando(false);
        toast.success('Fatura cancelada com sucesso!');
      })
      .catch((err) => {
        console.log(err)
        toast.error(err.message);
        setCarregando(false);
      });
  }

  async function segundaVia(rowData, invoice, boleto, data) {
    setCarregando(true);
    try {
      console.log(rowData);
      console.log(invoice);
      console.log(boleto);
      console.log(data);

      const dataFormatada = new Date(data).toISOString();

      const novaFatura = await api.post(`/pagamentos/segunda-via/stark/${rowData.id}`, {
        id_invoice: invoice,
        id_boleto: boleto,
        data: dataFormatada,
      });

      const vencimentoFormatado = format(new Date(data), 'dd/LL/yyyy');
      const rowDataAtualizado = {
        ...rowData,
        vencimento: data,
      };
      console.log(rowDataAtualizado)
      exportPDF(rowDataAtualizado);

      toast.success('Fatura criada com sucesso!');
      console.log(novaFatura);

      setExibir(false);
    } catch (err) {
      toast.error(`Erro ao gerar segunda via: ${err.message}`);
    } finally {
      setCarregando(false);
    }
  }

  return (
    <Container>
      <h2>Stark</h2>

      <FlexboxGrid justify="start" style={{ gap: '10px' }}>
        <InputGroup
          style={{ width: '300px' }}
        >
          <AutoComplete
            placeholder="Nome do Estabelecimento"
            width={200}
            data={nomesEstabelecimentos.map((p) => ({
              label: p.nome_fantasia,
              value: p.nome_fantasia,
              id_stark: p.id,
            }))}
            onSelect={async (value, item) => {
              setTimeout(() => {
                setEstabelecimentoSelecionado(item);
              }, 1000);
            }}
            onChange={(value) => {
              if (value !== '') {
                buscarNomesEstabelecimentos(value);
              } else {
                setEstabelecimentoSelecionado('');
                carregarFaturas();
              }
            }}
          />
          <InputGroup.Button>
            <SearchIcon />
          </InputGroup.Button>
        </InputGroup>
        <InputDataRange
          ranges={[]}
          isoWeek
          oneTap
          locale={locale}
          onClean={() => {
            setDatas([]);
          }}
          onChange={setDatas}
          value={datas}
          hoverRange="week"
        />
        <CheckPicker
          value={statusFiltro}
          title="Filtro Status"
          toggleComponentClass={Button}
          appearance="default"
          searchable={false}
          placeholder="Selecione Status"
          data={statusFatura}
          onChange={setStatusFiltro}
        />

        <InputGroup
          style={{ width: '150px' }}
        >
          <Input
            value={pedidoII}
            placeholder="N. Pedido"
            onChange={(value) => {
              setPedidoII(value);
              if (value.replace(/[a-z]+|[A-Z]+|-/gm, '').length > 4)
                setPedido(value.replace(/[a-z]+|[A-Z]+|-/gm, ''));
              if (value.length === 0) setPedido(value);
            }}
          />
          <InputGroup.Button>
            <SearchIcon />
          </InputGroup.Button>
        </InputGroup>

        <GerarFaturaStark
          atualizarTabela={carregarFaturas}
        />

        <GerarFaturasSemanais
          atualizarTabela={carregarFaturas}
          style={{ marg: 10 }}
          setCarregando={setCarregando}
        />

      </FlexboxGrid>
      <FlexboxGrid style={{ width: '100%', marginTop: 20 }}>

        <TablePagination
          carregando={carregando}
          dados={faturas.lista}
          onChangePage={setPagina}
          // style={{ width: 1000 }}
          quantidade={faturas.quantidade}
          setOrderBy={setOrderBy}
        >
          <Column resizable width={80}>
            <HeaderCell>Opções</HeaderCell>
            <DropDownCell
              setExibir={setExibir}
              setIdFatura={setIdFatura}
              setIdInvoice={setIdInvoice}
              setIdBoleto={setIdBoleto}
              setExibirCancelar={setExibirCancelar}
              setRelatorioRowData={setRelatorioRowData}
              dataKey="id"
            />
          </Column>

          <Column resizable sortable width={200}>
            <HeaderCell>Nome</HeaderCell>
            <CelulaFormatada dataKey="nome_fantasia" />
          </Column>

          <Column resizable width={100}>
            <HeaderCell>Baixar PDF</HeaderCell>
            <CelulaAcaoBaixarPdf toggleMode={toggleMode} onClick={exportPDF} celulaStark />
          </Column>

          <Column resizable width={200}>
            <HeaderCell>Cnpj</HeaderCell>
            <CelulaFormatada dataKey="cpf_cnpj" />
          </Column>

          <Column resizable sortable width={130}>
            <HeaderCell>Valor</HeaderCell>
            <CelulaFormatada dataKey="valor" />
          </Column>

          <Column resizable sortable width={150}>
            <HeaderCell>Data de criação</HeaderCell>
            <CelulaFormatada dataKey="created_at" />
          </Column>

          <Column resizable sortable width={170}>
            <HeaderCell>Data de vencimento</HeaderCell>
            <CelulaFormatada dataKey="data_vencimento" />
          </Column>

          <Column resizable sortable width={100}>
            <HeaderCell>Boleto</HeaderCell>
            <CelulaFormatada dataKey="status_boleto" />
          </Column>

          <Column resizable sortable width={100}>
            <HeaderCell>Invoice</HeaderCell>
            <CelulaFormatada dataKey="status_invoice" />
          </Column>

          {/* <Column resizable sortable width={200}>
            <HeaderCell>Pedido</HeaderCell>
            <CelulaFormatada dataKey="numero_pedido" />
          </Column> */}

        </TablePagination>
      </FlexboxGrid>
      <Modal open={exibir} onClose={() => setExibir(false)} size="xs">
        <Modal.Header>Segunda Via</Modal.Header>
        <Modal.Body>
          <FlexboxGrid
            style={{ justifyContent: 'center', alignItems: 'center', gap: 10 }}
          >
            <p>Informe a nova data de vencimento:</p>
            <InputData
              cleanable={false}
              ranges={[]}
              isoWeek
              oneTap
              locale={locale}
              value={filtroData}
              onChange={setFiltroData}
              disabledDate={(data) => isBefore(data, subDays(new Date(), 1))}
            />
          </FlexboxGrid>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => setExibir(false)} appearance="subtle">
            Cancelar
          </Button>
          <Button
            appearance="primary"
            type="button"
            onClick={() => segundaVia(relatorioRowData, relatorioRowData.id_invoice, relatorioRowData.id_boleto, filtroData)}
          >
            Criar e Gerar PDF
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        open={exibirCancelar}
        onClose={() => setExibirCancelar(false)}
        size="xs"
      >
        <Modal.Header>Cancelar fatura</Modal.Header>
        <Modal.Body>
          <p>Deseja realmente cancelar a fatura?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => setExibirCancelar(false)} appearance="subtle">
            Cancelar
          </Button>
          <Button
            appearance="primary"
            type="button"
            onClick={() => {
              cancelarFatura(idFatura, idBoleto, idInvoice);
            }}
          >
            Confirmar
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  )
}

