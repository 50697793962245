/* eslint-disable react/prop-types */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/jsx-no-bind */
import React, { useEffect, useState } from 'react';
import { Plus } from '@rsuite/icons';

import {
  Table,
  Button,
  FlexboxGrid,
  Modal,
  Form,
  IconButton,
  Schema,
  Toggle,
} from 'rsuite';
import { toast } from 'react-toastify';

import { Container } from '~/styles/tabela';

import api from '~/services/api';
import {
  CelulaEditavel,
  CelulaEditavelDescricao,
  CelulaAcao,
} from '~/components/Celulas/components/CelulaEditavel';
import { TabelaSemPaginacao } from '~/components/TabelaSemPaginacao';
import PropTypes from 'prop-types';
import AdicionarIconeDialog from './components/AdicionarIconeDialog';
import AdicionarBannerDialog from './components/AdicionarBannerDialog';
import SearchTable from '../components/SearchTable';
import { ButtonFullBlue } from '../../../components/Buttons/ButtonFullBlue/styles';
import { ResponsiveFormTextArea } from '../../../components/ResponsiveForm';

const { Cell } = Table;

const { HeaderCell, Column } = Table;

const { StringType, NumberType } = Schema.Types;

const modelo = Schema.Model({
  nome_empresa: StringType().isRequired('Nome Obrigatório'),
  categoria: StringType().isRequired('Categoria Obrigatória'),
  descricao: StringType().isRequired('Descrição Obrigatória'),
});

function Parcerias() {
  const [dados, setDados] = useState({
    parceirosListaInicial: [],
    parceirosLista: [],
    parceirosListaFiltrada: [],
    filtro: '',
  });
  const [carregando, setCarregando] = useState(true);
  const [adicionarParceiroDialog, setadicionarParceiroDialog] =
    useState(false);
  const [camposInput, setCamposInput] = useState({});
  const [adicionarIconeDialog, setAdicionarIconeDialog] = useState({
    exibir: false,
    parceria: {},
  });
  const [adicionarBannerDialog, setAdicionarBannerDialog] = useState({
    exibir: false,
    parceria: {},
  });

  async function buscarParcerias() {
    const parcerias = await api.get('/empresa-parceira');
    const parcerias2 = await api.get('/empresa-parceira');

    const lista = parcerias.data.map((p) => ({
      ...p,
      icone: p.imagem && p.imagem.arquivo ? p.imagem.arquivo : '',
    }))

    setDados({
      parceirosListaInicial: parcerias2.data,
      parceirosLista: lista,
      parceirosListaFiltrada: lista,
    });
    setCarregando(false);
  }

  useEffect(() => {
    buscarParcerias();
  }, []);

  async function bloquearParceria(parceiro, status) {
    try {
      setCarregando(true);
      parceiro.ativado = !status;
      await api.put(`empresa-parceira/${parceiro.id}`, parceiro);
      setCarregando(false);
      buscarParcerias();
      toast.success('Status atualizado com sucesso');
    } catch (error) {
      toast.error(error.message);
    }
  }

  const CelulaAcaoAtivoInativo = ({ rowData, ...props }) => (
    <Cell {...props}>
      <Toggle
        defaultChecked={rowData.ativado}
        onChange={() => bloquearParceria(rowData, rowData.ativado)}
      />
    </Cell>
  );


  function filtrarLista(texto) {
    setDados({
      ...dados,
      parceirosListaFiltrada: dados.parceirosLista.filter((p) =>
        p.nome_empresa
          .toString()
          .toUpperCase()
          .includes(texto ? texto.toUpperCase() : '')
      ),
      filtro: texto,
    });
  }

  function alterarTextoCelula(id, key, value) {
    const novaLista = Object.assign([], dados.parceirosLista);
    novaLista.find((item) => item.id === id)[key] = value;
    setDados({ ...dados, parceirosLista: novaLista });
    filtrarLista(dados.filtro);
  }

  async function alterarEstadoCelula(id) {
    const novaLista = Object.assign([], dados.parceirosLista);
    const item = novaLista.find((parceiro) => parceiro.id === id);

    const itemInicial = dados.parceirosListaInicial.find(
      (parceiro) => parceiro.id === id
    )
    if (
      item.status === 'EDIT' &&
      (item.nome_empresa !== itemInicial.nome_empresa ||
        item.categoria !== itemInicial.categoria ||
        item.descricao !== itemInicial.descricao ||
        item.link !== itemInicial.link)
    ) {
      try {
        setCarregando(true);
        await api.put(`/empresa-parceira/${id}`, item)
        toast.success('Parceiro atualizado com sucesso');
        buscarParcerias();
      } catch (error) {
        toast.error(error.message);
      }
      setCarregando(false);
    } else {
      item.status = item.status ? null : 'EDIT';
      setDados({ ...dados, parceirosLista: novaLista });
      filtrarLista(dados.filtro);
    }
  }

  async function adicionarParceiro() {
    try {
      setCarregando(true);
      await api.post('/empresa-parceira', camposInput);
      toast.success('Parceiro criado com sucesso');
      setadicionarParceiroDialog(false);
      setCarregando(false);
    } catch (e) {
      toast.error(e.message);
      setCarregando(false);
    }
    buscarParcerias();
  }

  return (
    <Container>
      <h2>Parcerias</h2>
      <FlexboxGrid justify="start" style={{ gap: '10px' }}>
        <SearchTable estilo={{ width: '300px' }} filtrarLista={filtrarLista} />
        <IconButton
          style={{ marginTop: '0' }}
          icon={<Plus />}
          onClick={() => {
            setadicionarParceiroDialog(true);
          }}
        />
      </FlexboxGrid>
      <FlexboxGrid style={{ width: '100%', marginTop: 10 }}>
        <TabelaSemPaginacao
          carregando={carregando}
          dados={dados.parceirosListaFiltrada}
        >
          <Column resizable width={150}>
            <HeaderCell>Ativo/Inativo</HeaderCell>
            <CelulaAcaoAtivoInativo dataKey="ativado" />
          </Column>
          <Column resizable width={240} sortable>
            <HeaderCell>Nome Empresa</HeaderCell>
            <CelulaEditavel dataKey="nome_empresa" onChange={alterarTextoCelula} />
          </Column>

          <Column resizable width={240} sortable>
            <HeaderCell>Categoria</HeaderCell>
            <CelulaEditavel dataKey="categoria" onChange={alterarTextoCelula} />
          </Column>
          <Column resizable width={240} sortable>
            <HeaderCell>Descrição</HeaderCell>
            <CelulaEditavelDescricao dataKey="descricao" onChange={alterarTextoCelula} />
          </Column>
          <Column resizable width={240}>
            <HeaderCell>Link</HeaderCell>
            <CelulaEditavel dataKey="link" onChange={alterarTextoCelula} />
          </Column>
          <Column resizable width={100}>
            <HeaderCell>Ícone</HeaderCell>
            <CelulaAcao
              dataKey="id"
              onClick={(id) => {
                const parceria = dados.parceirosListaFiltrada.find(
                  (p) => p.id === id
                );
                setAdicionarIconeDialog({
                  exibir: true,
                  parceria,
                });
              }}
            />
          </Column>
          <Column resizable width={100}>
            <HeaderCell>Banner</HeaderCell>
            <CelulaAcao
              dataKey="id"
              onClick={(id) => {
                const parceria = dados.parceirosListaFiltrada.find(
                  (p) => p.id === id
                );
                setAdicionarBannerDialog({
                  exibir: true,
                  parceria,
                });
              }}
            />
          </Column>

          <Column resizable width={100}>
            <HeaderCell>Editar</HeaderCell>
            <CelulaAcao
              dataKey="id"
              onClick={alterarEstadoCelula}
            // onDelete={abrirApagarDialog}
            />
          </Column>
        </TabelaSemPaginacao>
      </FlexboxGrid>

      <Modal
        backdrop="static"
        open={adicionarParceiroDialog}
        onClose={() => setadicionarParceiroDialog(false)}
        size="xs"
      >
        <Form
          model={modelo}
          onChange={setCamposInput}
          onSubmit={(status) => status && adicionarParceiro()}
        >
          <Modal.Header>
            <h3>Adicionar Parceiros</h3>
          </Modal.Header>
          <Modal.Body>
            <Form.Group>
              <Form.ControlLabel>Nome Empresa</Form.ControlLabel>
              <Form.Control name="nome_empresa" />
            </Form.Group>
            <Form.Group>
              <Form.ControlLabel>Categoria</Form.ControlLabel>
              <Form.Control name="categoria" />
            </Form.Group>
            <Form.Group>
              <ResponsiveFormTextArea
                label="Descrição"
                name="descricao"
                rows={5}
                componentClass="textarea"
              />
            </Form.Group>

            <Form.Group>
              <Form.ControlLabel>Link</Form.ControlLabel>
              <Form.Control name="link" />
            </Form.Group>
          </Modal.Body>

          <Modal.Footer>
            <Button
              onClick={() => setadicionarParceiroDialog(false)}
              appearance="subtle"
            >
              Cancelar
            </Button>
            <ButtonFullBlue type="submit" appearance="primary">
              Adicionar
            </ButtonFullBlue>
          </Modal.Footer>
        </Form>
      </Modal>

      <AdicionarIconeDialog
        exibir={adicionarIconeDialog.exibir}
        onFechar={() => {
          setAdicionarIconeDialog({
            exibir: false,
            parceria: {},
          });
        }}
        parceria={adicionarIconeDialog.parceria}
      />
      <AdicionarBannerDialog
        exibir={adicionarBannerDialog.exibir}
        onFechar={() => {
          setAdicionarBannerDialog({
            exibir: false,
            parceria: {},
          });
        }}
        parceria={adicionarBannerDialog.parceria}
      />
    </Container>
  );
}

Parcerias.propTypes = {
  rowData: PropTypes.func.isRequired,
};


export default Parcerias;
