/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Dropdown, IconButton, Popover, Table, Whisper } from 'rsuite';
import ArrowDownIcon from '@rsuite/icons/ArrowDown';
import PropTypes from 'prop-types';
import theme from '../../../styles/theme';

const { Cell } = Table;

const fundo = (data) => {
  let cor;
  if (data.status_invoice && data.status_boleto === 'Pendente' || data.status_invoice === 'Pendente' && data.status_boleto === 'Criado') {
    cor = theme.yellowColor;
  } else if (data.status_boleto === 'Pago' || data.status_invoice === 'Pago' ) {
    cor = theme.darkGreen;
  } else if (data.status_invoice && data.status_boleto === 'Cancelado') {
    cor = theme.lightRed;
  } else if (data.status === 'Parcialmente Pago') {
    cor = theme.lightGreen;
  } else if (data.status === 'Devolvido') {
    cor = theme.darkRed;
  } else if (data.status === 'Expirado') {
    cor = theme.darkGray;
  } else if (data.status === 'Autorizado') {
    cor = theme.primaryColor;
  }
  return cor;
};

const DropDownCell = ({
  dataKey,
  rowData,
  setExibir,
  setRelatorioRowData,
  setIdFatura,
  setExibirCancelar,
  setIdInvoice,
  setIdBoleto,
  ...props
}) => {
  return (
    <Cell
      {...props}
      dataKey={dataKey}
      style={{ padding: '5px', background: fundo(rowData), color: '#fff' }}
    >
      <Whisper
        placement="rightStart"
        trigger="click"
        speaker={
          <Popover full>
            <Dropdown.Menu>
              <Dropdown.Item
                key="Segunda Via"
                eventKey="Segunda Via"
                disabled={rowData.status_invoice !== 'Pendente' && rowData.status_boleto !== 'Criado' && true}
                onSelect={() => {
                  setExibir(true);
                  setRelatorioRowData(rowData);
                }}
              >
                Gerar 2ª via
              </Dropdown.Item>
              <Dropdown.Item
                key="Cancelar Fatura"
                eventKey="Cancelar Fatura"
                disabled={rowData.status_invoice !== 'Pendente' && rowData.status_boleto !== 'Criado' && true}
                onSelect={() => {
                  setIdFatura(rowData.id);
                  setIdInvoice(rowData.id_invoice)
                  setIdBoleto(rowData.id_boleto)
                  setExibirCancelar(true);
                }}
              >
                Cancelar Fatura
              </Dropdown.Item>
            </Dropdown.Menu>
          </Popover>
        }
      >
        <IconButton
          style={{ color: '#fff', background: fundo(rowData) }}
          appearance="subtle"
          icon={<ArrowDownIcon />}
        />
      </Whisper>
    </Cell>
  );
};

DropDownCell.propTypes = {
  rowData: PropTypes.oneOfType([PropTypes.object]).isRequired,
  dataKey: PropTypes.string.isRequired,
  setExibirCancelar: PropTypes.func.isRequired,
  setIdFatura: PropTypes.func.isRequired,
  setRelatorioRowData: PropTypes.oneOfType([PropTypes.object]).isRequired,
  setIdInvoice: PropTypes.func.isRequired,
  setIdBoleto: PropTypes.func.isRequired,
  setExibir: PropTypes.func.isRequired,
};

export default DropDownCell;
